<template>
    <div>
        <div class="content">


            <div class="B1">
                <img class="B1B1 showM" src="../../assets/footer/DAVISIND_MV.png">

                <div class="B1B3">
                    <p class="B1B3_T1">DavisInd Group es una empresa mexicana que ofrece soluciones de ingeniería a
                        nuestros clientes en las industrias automotriz, minera y de alimentos y bebidas.</p>
                    <p class="B1B3_T2">Ya sea que realicemos sistemas completamente integrados, líneas de producción o
                        gestión de proyectos, entregamos soluciones únicas a la industria de la automatización con
                        eficiencia y precisión.</p>

                </div>
                <div class="B1B3 Contacto">
                    <p class="B1B3_T1">Datos de <span class="accent"> contacto:</span></p>

                    <p class="B1B3_T1"> <img class="icon" src="../../assets/footer/IconHome.svg" alt=""> Blvd Eusebio
                        Kino 315 9-909 Lomas del Pitic, <br> Hermosillo, Sonora, Mex. CP 83010</p>

                    <p class="B1B3_T1"> <img class="icon" src="../../assets/footer/IconMail.svg" alt=""> <a
                            href="mailto:contact@davisindgroup.com"
                            style="color: inherit;">contact@davisindgroup.com</a> </p>

                    <p class="B1B3_T1"> <img class="icon" src="../../assets/footer/IconPhone.svg" alt=""> <a
                            href="tel:+13859003848" style="color: inherit;">+1 (385) 900-3848</a>
                    </p>
                   




                </div>
                <div class="B1B3 Contacto">
                    <div v-if="mostrar === 'true'">
                        <p class="B1B3_T1 bold">¿Estás buscando servicio <br> de maquinado en tu ciudad?</p>
                        <div class="ciudades">
                            <a href="/maquinado-industrial-hermosillo">
                                <p class="B1B3_T1">Maquinado industrial en Hermosillo </p>
                            </a>
                            <a href="/maquinado-industrial-obregon">
                                <p class="B1B3_T1">Maquinado industrial en Obregón </p>
                            </a>
                            <a href="/maquinado-industrial-guaymas">
                                <p class="B1B3_T1">Maquinado industrial en Guaymas </p>
                            </a>
                            <a href="/maquinado-industrial-nogales">
                                <p class="B1B3_T1">Maquinado industrial en Nogales </p>
                            </a>
                            <a href="/maquinado-industrial-cananea">
                                <p class="B1B3_T1">Maquinado industrial en Cananea </p>
                            </a>
                            <a href="/maquinado-industrial-san-luis-rio-colorado">
                                <p class="B1B3_T1">Maquinado industrial en San Luis Rio Colorado </p>
                            </a>
                            <a href="/maquinado-industrial-caborca">
                                <p class="B1B3_T1">Maquinado industrial en Caborca </p>
                            </a>
                            <a href="/maquinado-industrial-navojoa">
                                <p class="B1B3_T1">Maquinado industrial en Navojoa </p>
                            </a>
                        </div>
                    </div>
                    <div v-if="mostrar === 'false'">
                        <p class="B1B3_T1 bold">Servicios especializados: </p>

                        <p class="B1B3_T1">Maquinaria CNC {{ ciudad }}</p>

                        <p class="B1B3_T1">Taller de torno {{ ciudad }} </p>

                        <p class="B1B3_T1">Fresadora {{ ciudad }} </p>

                        <p class="B1B3_T1">Fabricación de piezas {{ ciudad }} </p>

                    </div>
                </div>
                <div class="B1B4">
                    <img class="B1B1 showD" src="../../assets/footer/DAVISIND_MV.png">
                    <img class="B1B4_I1 showM" src="../../assets/footer/FooterImg.png">
                    <!--<img class="B1B4_I2 showM" src="../assets/footer/CIRCLEUP_MV.png">-->
                    <img class="B1B4_I1 showD" src="../../assets/footer/FooterImg.png">
                    <!--<img class="B1B4_I2 showD" src="../assets/footer/CIRCLEUP_DT.png">-->
                </div>
            </div>

            <div class="B2">
                <p>2024 Davis Ind Group | <br class="OnlyM"> All rights reserved</p>
                <div class="B2B2">
                    <!--<img src="../assets/footer/FB_DT.svg">
                    <img src="../assets/footer/IG_DT.svg">-->
                    <div class="rsIcons">
                        <!-- <a href="#">
                            <img src="../../assets/footer/Icon-FB.svg">
                        </a>
                        <a href="#">
                            <img src="../../assets/footer/Icon-IG.svg">
                        </a>
                        <a href="#">
                            <img src="../../assets/footer/Icon-TW.svg">
                        </a> -->
                        <a href="https://www.linkedin.com/company/davisind-group" target="_blank"
                            rel="noopener noreferrer">
                            <img src="../../assets/footer/Icon-LN.svg">
                        </a>
                    </div>

                    <p>davisindgroup.com</p>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
export default {
    props: {
        ciudad: {
            type: String,
            required: true,
        },
        mostrar: {
            type: String,
            required: true,
        },
    },
    // ...
};
</script>

<style scoped>
.rsIcons {
    display: flex;
    flex-direction: row;
    gap: 0.2vw;
}

.content {
    background-image: url('../../assets/footer/BACKFOOTER_MV.png');
    width: 100%;
    height: 248.5981308411215VW;
    background-repeat: no-repeat;
    background-size: cover;
}

.bold {
    font-weight: bold !important;
}

.ciudades {
    display: flex;
    flex-direction: column;
}

img.B1B1 {
    width: 40.887850467289724VW;
    height: 14.252336448598129VW;
    margin-top: 16.355140186915886VW;
    margin-left: 15.887850467289718VW;
    margin-bottom: 10.514018691588785VW;
}

.B1B2 {
    display: flex;
    margin-left: 14.485981308411214VW;
}

.B1B2_B1 {
    margin-right: 10.046728971962617VW;
}

.B1B2_B1 ul {
    margin: 0vw;
    padding-left: 5vw;
}

.B1B2_B1 li {
    color: var(--color-5);
    margin-bottom: 12vw;
}

.B1B2_B1 li a {
    font-size: 3.2710280373831773VW;
    font-weight: 400;
    color: var(--color-5);
    font-family: var(--MOSB_Light);
    text-decoration-color: transparent;
}

.B1B2_B2 ul {
    margin: 0vw;
}

.B1B2_B2 li {
    margin-bottom: 12vw;
    color: var(--color-5);
}

.B1B2_B2 li a {
    font-size: 3.2710280373831773VW;
    font-weight: 400;
    color: var(--color-5);
    font-family: var(--MOSB_Light);
    text-decoration-color: transparent;
}

.B1B3 {
    width: fit-content;
}

.Contacto {
    margin-left: 2vw !important;
}

.B1B3 p {
    font-size: 3.2710280373831773VW;
    font-weight: 400;
    font-family: var(--OpenSans);
    line-height: 6.107476635514019VW;
    color: var(--color-5);
    margin: auto;
}

.accent {
    font-weight: 600;
    color: var(--color-6);
}

p.B1B3_T1 {
    width: 78.5vw;
    margin-bottom: 7vw;
}

p.B1B3_T2 {
    width: 78.5vw;
}

.B1B4 {
    height: auto;
}

img.B1B4_I1 {
    width: 94.168224VW;
    height: auto;
    object-fit: contain;
    margin-bottom: 25vw;
}

img.B1B4_I2 {
    position: absolute;
    width: 27.570093457943923VW;
    height: 27.570093457943923VW;
    margin-left: -37vw;
    margin-top: 19vw;
}

.B2 {
    border-top: 0.1vw solid var(--color-5);
    padding-top: 9vw;
}

.B2 p {
    display: flex;
    font-size: 3.2710280373831773VW;
    font-weight: 400;
    font-family: var(--OpenSans);
    color: var(--color-5);
    justify-content: center;
    margin: 0vw;
    margin-bottom: 7vw;
}

.B2B2 {
    display: flex;
    gap: 0.5vw;
    /*width: 42.99065420560748VW;*/
    width: fit-content;
    align-items: center;
    justify-content: space-between;
    margin: auto;
}

.B2B2 img {
    width: 6.074766355140187VW;
    height: auto;
}

.B2B2 p img {
    width: 1vw;
}

.showD {
    display: none !important;
}

.icon {
    height: 1vw;
    width: 1vw;
    object-fit: contain;
    transform: translateY(0.1vw);
}

.OnlyM {
    display: none;
}

@media(min-width: 769px) {

    .showD {
        display: block !important;
    }

    .showM {
        display: none !important;
    }

    .content {
        background-image: url('../../assets/footer/BACKFOOTER_DT.png');
        height: 28.072916666666664VW;
    }

    .B1 {
        display: flex;
        height: 23vw;
    }

    img.B1B1 {
        position: absolute;
        width: 13.333333333333334VW;
        height: 4.635416666666667VW;
        margin-left: 23vw;
        margin-top: 3vw;
    }

    .B1B2 {
        display: block;
        margin-top: 5VW;
    }

    .B1B2_B1 {
        margin-right: 0vw;
    }

    .B1B2_B1 ul {
        padding-left: 2vw;
    }

    .B1B2_B1 li {
        margin-bottom: 3vw;
    }

    .B1B2_B1 li a {
        font-size: 0.7291666666666666VW;
    }

    .B1B2_B2 ul {
        padding-left: 2vw;
    }

    .B1B2_B2 li {
        margin-bottom: 3vw;
    }

    .B1B2_B2 li a {
        font-size: 0.7291666666666666VW;
    }

    .B1B3 {
        margin-top: 3VW;
        margin-left: 7.34375VW;
    }

    .B1B3 p {
        font-size: 0.8375VW;
        line-height: 1.7500000000000002VW;
    }

    p.B1B3_T1 {
        width: 15.5vw;
        margin-bottom: 0.1vw;
    }

    p.B1B3_T2 {
        width: 15.5vw;
    }

    img.B1B4_I1 {
        position: absolute;
        width: 28.241667VW;
        height: auto;
        margin-top: -3.64vw;
        margin-left: 1vw;
    }

    img.B1B4_I2 {
        width: 6.145833333333333VW;
        height: 6.145833333333333VW;
        margin-left: 39vw;
        margin-top: 8vw;
    }

    .B2 {
        display: flex;
        width: 89.58333333333334VW;
        margin: auto;
        border-top: 0.1vw solid var(--color-5);
        padding-top: 0vw;
        flex-flow: row-reverse;
        height: 5vw;
        align-items: center;
    }

    .B2B2 img {
        width: 1.2166666666666666VW;
    }

    .B2 p {
        display: flex;
        font-size: 0.9375VW;
        font-weight: 400;
        font-family: var(--OpenSans);
        color: var(--color-5);
        justify-content: center;
        margin: 0vw;
        margin-bottom: 0vw;
        margin-right: 35vw;
    }

    .B2B2 {
        display: flex;
        width: 6.406249999999999VW;
        align-items: center;
        justify-content: space-between;
        margin: auto;
        margin-left: 5vw;
    }

    .B2B2 a {
        text-decoration: none;
        display: flex;
        gap: 1vw;
    }
}

@media(max-width: 769px) {
    .B1 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .content {
        height: auto;
    }

    .icon {
        width: 5vw;
        height: 5vw;
        object-fit: contain;
        transform: translateY(1vw);
    }

    img.B1B1 {
        margin-left: 0;
    }

    .Contacto {
        margin-left: 0 !important;
        margin-top: 8vw !important;
    }

    .B2 {
        margin: 0 5vw;
    }

    .OnlyM {
        display: block;
    }

    .B2 p {
        text-align: center;
        transform: translateY(5vw);
    }

    .B2B2 {
        align-items: center;
        flex-direction: column;
        transform: translateY(-45vw);
    }

    .B2B2 p {
        line-height: 0vw;
        margin: 0;
        font-size: 5vw;
        font-weight: 600;
    }

    .rsIcons {
        display: flex;
        flex-direction: row;
        gap: 0.8vw;
    }
}
</style>