var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content"},[_c('div',{staticClass:"B1"},[_c('img',{staticClass:"B1B1 showM",attrs:{"src":require("../../assets/footer/DAVISIND_MV.png")}}),_vm._m(0),_vm._m(1),_c('div',{staticClass:"B1B3 Contacto"},[(_vm.mostrar === 'true')?_c('div',[_vm._m(2),_vm._m(3)]):_vm._e(),(_vm.mostrar === 'false')?_c('div',[_c('p',{staticClass:"B1B3_T1 bold"},[_vm._v("Servicios especializados: ")]),_c('p',{staticClass:"B1B3_T1"},[_vm._v("Maquinaria CNC "+_vm._s(_vm.ciudad))]),_c('p',{staticClass:"B1B3_T1"},[_vm._v("Taller de torno "+_vm._s(_vm.ciudad)+" ")]),_c('p',{staticClass:"B1B3_T1"},[_vm._v("Fresadora "+_vm._s(_vm.ciudad)+" ")]),_c('p',{staticClass:"B1B3_T1"},[_vm._v("Fabricación de piezas "+_vm._s(_vm.ciudad)+" ")])]):_vm._e()]),_vm._m(4)]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"B1B3"},[_c('p',{staticClass:"B1B3_T1"},[_vm._v("DavisInd Group es una empresa mexicana que ofrece soluciones de ingeniería a nuestros clientes en las industrias automotriz, minera y de alimentos y bebidas.")]),_c('p',{staticClass:"B1B3_T2"},[_vm._v("Ya sea que realicemos sistemas completamente integrados, líneas de producción o gestión de proyectos, entregamos soluciones únicas a la industria de la automatización con eficiencia y precisión.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"B1B3 Contacto"},[_c('p',{staticClass:"B1B3_T1"},[_vm._v("Datos de "),_c('span',{staticClass:"accent"},[_vm._v(" contacto:")])]),_c('p',{staticClass:"B1B3_T1"},[_c('img',{staticClass:"icon",attrs:{"src":require("../../assets/footer/IconHome.svg"),"alt":""}}),_vm._v(" Blvd Eusebio Kino 315 9-909 Lomas del Pitic, "),_c('br'),_vm._v(" Hermosillo, Sonora, Mex. CP 83010")]),_c('p',{staticClass:"B1B3_T1"},[_c('img',{staticClass:"icon",attrs:{"src":require("../../assets/footer/IconMail.svg"),"alt":""}}),_vm._v(" "),_c('a',{staticStyle:{"color":"inherit"},attrs:{"href":"mailto:contact@davisindgroup.com"}},[_vm._v("contact@davisindgroup.com")])]),_c('p',{staticClass:"B1B3_T1"},[_c('img',{staticClass:"icon",attrs:{"src":require("../../assets/footer/IconPhone.svg"),"alt":""}}),_vm._v(" "),_c('a',{staticStyle:{"color":"inherit"},attrs:{"href":"tel:+13859003848"}},[_vm._v("+1 (385) 900-3848")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"B1B3_T1 bold"},[_vm._v("¿Estás buscando servicio "),_c('br'),_vm._v(" de maquinado en tu ciudad?")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ciudades"},[_c('a',{attrs:{"href":"/maquinado-industrial-hermosillo"}},[_c('p',{staticClass:"B1B3_T1"},[_vm._v("Maquinado industrial en Hermosillo ")])]),_c('a',{attrs:{"href":"/maquinado-industrial-obregon"}},[_c('p',{staticClass:"B1B3_T1"},[_vm._v("Maquinado industrial en Obregón ")])]),_c('a',{attrs:{"href":"/maquinado-industrial-guaymas"}},[_c('p',{staticClass:"B1B3_T1"},[_vm._v("Maquinado industrial en Guaymas ")])]),_c('a',{attrs:{"href":"/maquinado-industrial-nogales"}},[_c('p',{staticClass:"B1B3_T1"},[_vm._v("Maquinado industrial en Nogales ")])]),_c('a',{attrs:{"href":"/maquinado-industrial-cananea"}},[_c('p',{staticClass:"B1B3_T1"},[_vm._v("Maquinado industrial en Cananea ")])]),_c('a',{attrs:{"href":"/maquinado-industrial-san-luis-rio-colorado"}},[_c('p',{staticClass:"B1B3_T1"},[_vm._v("Maquinado industrial en San Luis Rio Colorado ")])]),_c('a',{attrs:{"href":"/maquinado-industrial-caborca"}},[_c('p',{staticClass:"B1B3_T1"},[_vm._v("Maquinado industrial en Caborca ")])]),_c('a',{attrs:{"href":"/maquinado-industrial-navojoa"}},[_c('p',{staticClass:"B1B3_T1"},[_vm._v("Maquinado industrial en Navojoa ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"B1B4"},[_c('img',{staticClass:"B1B1 showD",attrs:{"src":require("../../assets/footer/DAVISIND_MV.png")}}),_c('img',{staticClass:"B1B4_I1 showM",attrs:{"src":require("../../assets/footer/FooterImg.png")}}),_c('img',{staticClass:"B1B4_I1 showD",attrs:{"src":require("../../assets/footer/FooterImg.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"B2"},[_c('p',[_vm._v("2024 Davis Ind Group | "),_c('br',{staticClass:"OnlyM"}),_vm._v(" All rights reserved")]),_c('div',{staticClass:"B2B2"},[_c('div',{staticClass:"rsIcons"},[_c('a',{attrs:{"href":"https://www.linkedin.com/company/davisind-group","target":"_blank","rel":"noopener noreferrer"}},[_c('img',{attrs:{"src":require("../../assets/footer/Icon-LN.svg")}})])]),_c('p',[_vm._v("davisindgroup.com")])])])
}]

export { render, staticRenderFns }