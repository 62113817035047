<template >
  <div id="section-blg">
    <navigator/>

    <div id="cnt-blg">
      <h1
        class="title-blg"
        data-aos="fade-zoom-in"
        data-aos-easing="ease-in-back"
        data-aos-duration="600"
      >
        Blog
      </h1>

      <div id="body-blog">
        <a
          v-for="(post, key) in blogs"
          :key="key" :href="'blog-es/' + post.url">
            <div
              class="post-box"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="600"
            >
              <div class="post-img">
                <img :src="srcImg(post.image)" alt="Post image." />
              </div>

              <div class="data-box">
                <h2 class="subtitle-blg">{{ post.title }}</h2>
                <div class="row-box">
                  <p class="post-fecha">{{ convertdate(post.dateblog) }}</p>
                </div>
                <p class="post-cont">{{ post.descrp }}</p>
                <a :href="'blog-es/' + post.url" class="dflx div_more">
                  <h5 class="more">más</h5>
                  <img
                    class="imarrow_blg"
                    src="@/assets/blog/ar1.png"
                    alt="arrow rigth"
                  />
                </a>
              </div>
            </div>          
          </a>

      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Navigator from "@/components/SimpleNavigator";
import Footer from "@/components/landing/LandingFooter.vue";
import img1 from "@/assets/blog/img1.png";
import img2 from "@/assets/blog/img2.png";
import img3 from "@/assets/blog/img3.png";

import { mapActions } from "vuex";

import { url, endpointCodes } from "../../global/index";

import es from 'dayjs/locale/es'
import relativeTime from 'dayjs/plugin/relativeTime'

export default {
  name: "BlogView",
  components: {
    Navigator,
    Footer,
  },
  data() {
    return {};
  },
  async created() {
    await this.getAllInfoBlo("blog");
  },
  computed: {
    blogs() {
      let arr = this.$store.getters["blog/data"]; 
      if (!Array.isArray(arr)) {
        return [];
      } 
      let array = []; 
      for (let i = 0; i < arr.length; i++) {
        console.log (arr[i])
        if (arr[i].statusblog == "VISIBLE" && arr[i].id_category == 2) {
          array.push(arr[i]);
        }
      } 
      return array;
    },
  },
  methods: {
    ...mapActions("blog", ["getAllInfoBlo"]),
    ...mapActions("blog", ["setHistoryOptionBlo"]),
    ...mapActions("blog", ["setAddedBlo"]),

    status_img: async function (img) {
      let payload = {
        option: "blog",
        image: img,
      };
      let response = "";

      try {
        response = await this.$store.dispatch("main/getStatusImage", payload);

        if (!response?.data?.status) {
        } else {
          response = "";
        }
      } catch (err) {
        //console.log(err)
      }

      return response;
    },

    srcImg: function (img) {
      let src = `${url}/${endpointCodes.get}/blog-img/${img}`;

      return src;
    },
    convertdate: function(date){
        const dayjs = require('dayjs');
        dayjs.extend(relativeTime)
        let newsdata = dayjs(date).locale(es).format('YYYY-MMMM-DD')
        let fecha = newsdata.split('-')
              let dd = fecha[2]
              let mm = fecha[1]
              let yy = fecha[0]
        return fecha = mm+' '+dd+', '+yy
    },
  },
};
</script>

<style  scoped>
#section-blg {
  background-image: url("../../assets/servicios/log.png");
  background-repeat: no-repeat;

  background-size: 58.7vw;
  background-position: 56.3vw 2.5vw;
}
#cnt-blg {
  margin: 0vw 3.2710280373831773vw;
}

h1.title-blg {
  text-align: center;
  margin: 0px auto;
  color: var(--color-2);
  font-family: var(--MOSB_Regular);
  font-style: normal;
  font-weight: 400;
  font-size: 8.177vw;
  line-height: 10.28vw;
  padding-top: 8.981vw;
  padding-bottom: 20.822429906542055vw;
}
h1.title-blg span {
  color: var(--color-1);
}

h2.subtitle-blg {
  font-family: var(--MOSB_Regular);
  font-style: normal;
  font-weight: 400;
  font-size: 3.5046728971962615vw;
  line-height: 134%;
  width: 100%;
  background: linear-gradient(180deg, #262ffd 0%, #06c5ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin: 2vw 0vw;
  margin-bottom: 1.8691588785046727vw;
}

#body-blog {
  width: 82.94392523364486vw;
  overflow: hidden;

  margin: 0px auto;
  margin-bottom: 34.81308411214953vw;
}
.post-box {
  border: 1px solid rgba(103, 100, 103, 1);
  margin-bottom: 2.570093457943925vw;
}
.post-img {
  width: 75vw;
  margin: 3.9719626168224296vw auto;
}
.post-img img {
  width: 100%;
  height: 37.850467289719624vw;
}
.data-box {
  width: 67.99065420560748vw;
  margin: 0px auto;
}
p.post-autor,
p.post-fecha {
  font-family: var(--MOSB_Regular);
  font-style: normal;
  font-weight: 400;
  font-size: 2.336448598130841vw;
  line-height: 134%;
  /* or 13px */
  color: #676467;
  width: 50%;
}
p.post-autor {
  padding-right: 9vw;
  text-align: right;
}
p.post-fecha {
  text-align: left;
    text-transform: capitalize;
}

p.post-cont {
  font-family: var(--OpenSans);
  font-style: normal;
  font-weight: 400;
  font-size: 3.7383177570093453vw;
  line-height: 152.68%;
  display: none;
  /* or 24px */

  color: #676467;
}

h5.more {
  font-family: var(--MOSB_Regular);
  font-style: normal;
  font-weight: 400;
  font-size: 2.570093457943925vw;
  line-height: 3.2710280373831773vw;
  text-align: center;
  text-transform: uppercase;
  width: 11.682242990654206vw;
  color: #262ffd;
  margin-right: 3.9719626168224296vw;
}
img.imarrow_blg {
  width: 13.317757009345794vw;
  height: 2vw;
  padding-top: 5vw;
}
a.div_more {
  text-decoration: none;
}
.div_more {
  margin-top: 3.9719626168224296vw;
  margin-bottom: 5.841121495327103vw;
  cursor: pointer;
}
.row-box {
  display: flex;
}
  #body-blog a{
    text-decoration-color: transparent;

  }

@media (min-width: 1024px) {
  .row-box {
    display: block;
  }
  #body-blog {
    width: 87.166667vw;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 13.645833333333332vw;
  }
  .post-box {
    width: 26.875vw;
    margin-right: 1.7708333333333333vw;
    margin-bottom: 1.8229166666666667vw;
  }

  .post-img {
    width: 24.114583333333332vw;
  }

  .post-img img {
    width: 100%;
    height: 16.041666666666668vw;
  }
  .data-box {
    width: 20.677083333333332vw;
  }
  h2.subtitle-blg {
    font-size: 1.1458333333333333vw;
  }

  p.post-cont {
    display: block;
    font-size: 0.8333333333333334vw;
  }

  p.post-autor,
  p.post-fecha {
    font-size: 0.6770833333333334vw;
    text-align: left;
  }
  p.post-autor {
    margin-top: -0.2vw;
    margin-bottom: 2.2916666666666665vw;
  }

  .div_more {
    margin-top: 2.083333333333333vw;
    margin-bottom: 2.083333333333333vw;
  }
  h5.more {
    font-size: 0.6770833333333334vw;
    text-align: left;
    margin: 0px;
    margin-right: 0.8854166666666666vw;
    width: 2.604166666666667vw;
  }
  img.imarrow_blg {
    width: 2.96875vw;
    height: 0.3vw;
    margin: 0px;
    padding-top: 1.5vw;
  }

  h1.title-blg {
    text-align: left;
    padding-top: 6.615vw;
    padding-bottom: 3vw;
    line-height: 6vw;
    font-size: 3.6458333333333335vw;
    margin-left: 2.5vw;
  }
  #section-blg {
    background-position: 51vw -22.5vw;
  }
}
</style>

<!-- */

 jsonBlog: [
        {
          id: 0,
          url: "blog-post-0",
          image: img1,
          title:
            "The Mexican Factory Automation & Industrial Controls Market – Forecast to 2023: IoT & M2M Technologies are Driving Market Growth – ResearchAndMarkets.com",
          date: "Mayo 27, 2017",
          autor: "Staff",
          content:
            "Almighty, O my friend — but it is too much for my strength — I sink under the weight of the splendour of these visions!",
        },
        {
          id: 1,
          url: "blog-post-1",
          image: img2,
          title:
            "The Mexican Factory Automation & Industrial Controls Market – Forecast to 2023: IoT & M2M Technologies are Driving Market Growth – ResearchAndMarkets.com",
          date: "Mayo 27, 2017",
          autor: "Staff",
          content:
            "Almighty, O my friend — but it is too much for my strength — I sink under the weight of the splendour of these visions!",
        },
        {
          id: 2,
          url: "blog-post-2",
          image: img3,
          title:
            "The Mexican Factory Automation & Industrial Controls Market – Forecast to 2023: IoT & M2M Technologies are Driving Market Growth – ResearchAndMarkets.com",
          date: "Mayo 27, 2017",
          autor: "Staff",
          content:
            "Almighty, O my friend — but it is too much for my strength — I sink under the weight of the splendour of these visions!",
        },
        {
          id: 3,
          url: "blog-post-3",
          image: img1,
          title:
            "The Mexican Factory Automation & Industrial Controls Market – Forecast to 2023: IoT & M2M Technologies are Driving Market Growth – ResearchAndMarkets.com",
          date: "Mayo 27, 2017",
          autor: "Staff",
          content:
            "Almighty, O my friend — but it is too much for my strength — I sink under the weight of the splendour of these visions!",
        },
        {
          id: 4,
          url: "blog-post-4",
          image: img2,
          title:
            "The Mexican Factory Automation & Industrial Controls Market – Forecast to 2023: IoT & M2M Technologies are Driving Market Growth – ResearchAndMarkets.com",
          date: "Mayo 27, 2017",
          autor: "Staff",
          content:
            "Almighty, O my friend — but it is too much for my strength — I sink under the weight of the splendour of these visions!",
        },
        {
          id: 5,

          url: "blog-post-5",
          image: img3,
          title:
            "The Mexican Factory Automation & Industrial Controls Market – Forecast to 2023: IoT & M2M Technologies are Driving Market Growth – ResearchAndMarkets.com",
          date: "Mayo 27, 2017",
          autor: "Staff",
          content:
            "Almighty, O my friend — but it is too much for my strength — I sink under the weight of the splendour of these visions!",
        },
        {
          id: 6,
          url: "blog-post-6",
          image: img1,
          title:
            "The Mexican Factory Automation & Industrial Controls Market – Forecast to 2023: IoT & M2M Technologies are Driving Market Growth – ResearchAndMarkets.com",
          date: "Mayo 27, 2017",
          autor: "Staff",
          content:
            "Almighty, O my friend — but it is too much for my strength — I sink under the weight of the splendour of these visions!",
        },
        {
          id: 7,
          url: "blog-post-7",
          image: img2,
          title:
            "The Mexican Factory Automation & Industrial Controls Market – Forecast to 2023: IoT & M2M Technologies are Driving Market Growth – ResearchAndMarkets.com",
          date: "Mayo 27, 2017",
          autor: "Staff",
          content:
            "Almighty, O my friend — but it is too much for my strength — I sink under the weight of the splendour of these visions!",
        },
        {
          id: 8,
          url: "blog-post-8",
          image: img3,
          title:
            "The Mexican Factory Automation & Industrial Controls Market – Forecast to 2023: IoT & M2M Technologies are Driving Market Growth – ResearchAndMarkets.com",
          date: "Mayo 27, 2017",
          autor: "Staff",
          content:
            "Almighty, O my friend — but it is too much for my strength — I sink under the weight of the splendour of these visions!",
        },
      ],
-->